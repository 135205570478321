import React, { useEffect, useState } from "react";
import {
    Grid,
    Button,
    MenuItem,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import CrossIcon from "@material-ui/icons/Close";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { baseApiURL } from "../../Utilities/utility";

import { tableHeaderStyle } from "../../SharedComponents/SharedComponentStyles";
//import Alert from "@material-ui/lab/Alert";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => (
        <Clear {...props} ref={ref} className="bg-transparent" />
    )),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} className="bg-transparent" />
    )),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownwardIcon {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function UserActivity() {
    var columns = [
        { title: "id", field: "id", hidden: true },
        { title: "First Name", field: "firstName" },
        { title: "Last Name", field: "lastName" },
        { title: "Email", field: "emailId" },
        { title: "Partner Name", field: "gid" },
        {
            title: "Role",
            field: "role",
        },
    ];

    var innerColumns = [
        /*{ title: "Course id", field: "courseId" },*/
        { title: "Course Name", field: "courseName" },
        { title: "Course Category", field: "categoryName" },
        { title: "Status", field: "courseStatus" },
        { title: "Last Accessed Date", field: "lastDateStr" },
        {
            title: "Score",
            field: "courseScore",
            cellStyle: {
                width: 20,
                maxWidth: 20,
            },
            headerStyle: {
                width: 20,
                maxWidth: 20,
            },
        },
        /*{ title: "Download Certificate", field: "courseURL", render: (row) => <div>{row.courseURL !== "" ? <a href={row.courseURL}><ArrowDownwardIcon className="checkIcon" /></a> : <CrossIcon className="crossIcon" />}</div> },*/
    ];

    const [data, setData] = useState([]); //table data
    const [courseData, setCourseData] = useState([]); //inner table data
    const token = useSelector((state) => state.saveTokenReducer.token);
    const bearerToken = useSelector(
        (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    const userData = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    function getUserList() {
        fetch(baseApiURL + "/User/GetUserList", {
            method: "get",
            headers: {
                Authorization: "Bearer " + bearerToken,
                UserEmail: userData.emailId,
                userId: uId,
                RoleName: userData.roleName,
                Gid: userData.gid,
                Roleid: userData.roleId,
                SiemensToken: token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setData(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }

    function getCourseList() {
        fetch(baseApiURL + "/Course/GetUserCourseActivity", {
            method: "get",
            headers: {
                Authorization: "Bearer " + bearerToken,
                UserEmail: userData.emailId,
                userId: uId,
                RoleName: userData.roleName,
                Gid: userData.gid,
                Roleid: userData.roleId,
                SiemensToken: token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setCourseData(data.result);
            });
    }

    useEffect(() => {
        getCourseList();
        getUserList();
    }, []);

    return (
        <div className="bg-landing-page">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <h3>User Activity</h3>
                        </Grid>
                        <Grid item xs={9} className="d-flex jc-flex-end">
                            <a href={baseApiURL + "/User/ExportUserCourseReport"}>
                                <Button
                                    className="leap-button--primary rseume-course-btn"
                                    id="DownloadDataBtn">
                                    Download Report
                                </Button>
                            </a>
                        </Grid>
                    </Grid>
                    <MaterialTable
                        title=""
                        columns={columns}
                        data={data}
                        icons={tableIcons}
                        options={{
                            headerStyle: tableHeaderStyle,
                            draggable: false,
                        }}
                        detailPanel={(rowData) => {
                            const filtered = courseData.filter((x) => x.userId == rowData.id);
                            return (
                                <div className="">
                                    <MaterialTable
                                        title=""
                                        columns={innerColumns}
                                        data={filtered}
                                        icons={tableIcons}
                                        options={{
                                            draggable: false,
                                        }}
                                    />
                                </div>
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
